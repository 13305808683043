import styled from 'styled-components';

export const Container = styled.div`
  min-width: calc(100% - 88px);

  h1 {
    font-size: 26px;
    font-weight: 500;
  }

  hr {
    background: #e1e1e1;
    opacity: 1;
  }

  a {
    :hover {
      color: unset;
    }
  }

  .container-fluid {
    max-width: 1460px;
  }

  .btn-new {
    border-radius: 4px;
    background: #ff6900;
    border: none;
  }

  .bg-white {
    border-radius: 8px;
    ::-webkit-scrollbar {
      background: transparent;
      width: 5px;
      height: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #002f6c;
      border-radius: 4px;
    }
  }

  .bg-balance {
    border-radius: 8px;
    background: linear-gradient(268deg, #ff9e2d 0%, #ff6900 100%);
  }
`;

export const Filter = styled.div`
  h2 {
    color: #000;
    font-family: 'Roboto';
  }
`;

export const TableList = styled.div`
  .btn-icons {
    background: #ff842f;
    border-radius: 20px;
    padding: 6px 8px;
  }
  .min-w {
    min-width: 2410px;
  }
  .table {
    > div:first-child {
      padding: 0px !important;
    }
    div {
      border: none;
    }
  }

  .content {
    ::-webkit-scrollbar {
      background: transparent;
      width: 5px;
      height: 13px;
      border: none;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #002f6c;
      border-radius: 10px;
    }
  }

  .tb-header {
    background-color: #002f6c;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 12px 16px;
  }

  .tb-body {
    padding: 0 0.5rem;
  }

  .tb-header .tb-row button {
    color: #fff;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    justify-content: center;

    svg {
      color: #fff !important;
    }
  }

  /* .tb-header .tb-row button:last-child {
    width: 138px !important;
    min-width: 138px !important;
  }

  .tb-body .tb-row div:last-child {
    min-width: 138px !important;
    width: auto !important;
  } */

  .tb-header .tb-row button:not(:first-child) {
    width: 200px;
  }

  .tb-header .tb-row button:first-child {
    svg {
      display: none;
    }
  }

  .tb-header .tb-row {
    button:nth-child(1) {
      width: 180px;
    }
    button:nth-child(3) {
      width: 140px;
    }
    button:nth-child(4) {
      width: 250px;
    }
    button:nth-child(5) {
      width: 110px;
    }
    button:nth-child(6) {
      width: 200px;
    }
    button:nth-child(7) {
      width: 130px;
    }
    button:nth-child(8) {
      width: 140px;
    }
    button:nth-child(9) {
      width: 150px;
    }
    button:nth-child(10) {
      width: 120px;
    }
    button:nth-child(11) {
      width: 110px;
    }
    button:nth-child(14) {
      width: 250px;
    }
  }

  .tb-body .tb-row {
    padding: 0.5rem 0.5rem;
    text-align: center;

    :not(:last-child) {
      border-bottom: 0.5px solid #e1e1e1;
    }

    div:not(:first-child) {
      color: #7e7e7e;
      font-family: 'Roboto';
      font-size: 16px;
      font-weight: 400;
      width: 200px;
      text-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    div:nth-child(1) {
      width: 180px;
    }
    div:nth-child(3) {
      width: 140px;
    }
    div:nth-child(4) {
      width: 250px;
    }
    div:nth-child(5) {
      width: 110px;
    }
    div:nth-child(6) {
      width: 200px;
    }
    div:nth-child(7) {
      width: 130px;
    }
    div:nth-child(8) {
      width: 140px;
    }
    div:nth-child(9) {
      width: 150px;
    }
    div:nth-child(10) {
      width: 120px;
    }
    div:nth-child(11) {
      width: 110px;
    }
    div:nth-child(14) {
      width: 250px;
    }
  }

  @media screen and (min-width: 992px) {
    /* .number-id:not(:nth-child(5)) {
      min-width: 45px;
      width: auto;
    } */
    .number-id {
      padding: 0px 7px;
    }
  }
`;
